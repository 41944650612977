import { Card, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import { useManagePurchase, usePurchasePackage } from '#/api/billingQueries';
import useAuth from '#/hooks/useAuth';
import SubscribeButton from '#/components/pages/Landing/Cards/SubscribeButton';
import MembersNumber from '#/components/pages/Landing/Cards/MembersNumber';
import BuyMore from '#/components/pages/Landing/Cards/BuyMore';
import PriceLabel from '#/components/pages/Landing/Cards/PriceLabel';

type PricingCardProps = {
  title: string;
  price: string;
  finfinder_price: string;
  finfinderSale: string;
  features: string[];
  productId: string;
  bestSeller?: boolean;
  priceSale?: boolean;
  promo_price?: string;
};

const PricingCard = ({
  title,
  price,
  finfinder_price,
  finfinderSale,
  features,
  productId,
  bestSeller,
  priceSale,
  promo_price,
}: PricingCardProps) => {
  const { user, isAuthenticated } = useAuth();

  const [startSession, setStartSession] = useState(false);
  const [getManagePortal, setGetManagePortal] = useState(false);
  const [membersNo, setMembersNo] = useState(0);

  const isFromFinFinder =
    localStorage.getItem('gg_partner_key') === 'finfinder';
  const promo50Package = user?.subscription_package === 'promo50';

  const { refetch } = useAuth();
  const theme = useTheme();
  const { translate } = useLocales();
  const { search } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: getStripeSession,
    isSuccess,
    isLoading,
  } = usePurchasePackage(
    productId,
    startSession,
    productId === 'family' ? membersNo : undefined,
    isFromFinFinder ? 'FINFINDER' : undefined
  );

  const { data: getManagePortalData, isLoading: gettingManagePortal } =
    useManagePurchase(getManagePortal);

  const handleStartSession = () => {
    setStartSession(true);
  };

  const handleGetManagePortal = () => {
    setGetManagePortal(true);
  };

  const handlePrice = () => {
    if (isFromFinFinder) {
      return finfinder_price;
    }
    if (promo50Package) {
      return promo_price;
    }
    return price;
  };

  const translatedFeatures = features.map((feature) => translate(feature));

  const purchasedCard = user?.subscription_package === productId;

  const isHomePage = location.pathname === '/';

  const handleStripeSession = () => {
    if (!isAuthenticated) {
      navigate('/vorsorge');
      return;
    }
    if (user?.subscription_package === productId) {
      handleGetManagePortal();
    } else {
      handleStartSession();
    }
  };

  const handleMembersNo = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMembersNo(Number(e.target.value));
  };

  const showSubscribeButton =
    !isAuthenticated ||
    (user?.subscription_package !== productId &&
      (user?.subscription_package === 'no' ||
        user?.subscription_package === 'starter' ||
        user?.subscription_package === 'promo50'));

  const disableMemberInput =
    user?.subscription_package !== 'no' &&
    user?.subscription_package !== 'promo50' &&
    user?.subscription_package !== 'family' &&
    user?.subscription_package !== 'family_member';

  const disableLowFamilyPurchase =
    user?.subscription_package === 'no' &&
    productId === 'family' &&
    membersNo < 2;

  useEffect(() => {
    if (getStripeSession?.session && isSuccess) {
      window.location.href = getStripeSession.session.url;
    }
    // eslint-disable-next-line
  }, [getStripeSession?.session, isSuccess]);

  useEffect(() => {
    if (getManagePortalData?.session) {
      window.location.href = getManagePortalData.session.url;
    }
    // eslint-disable-next-line
  }, [getManagePortalData?.session]);

  useEffect(() => {
    if (search === '?success=true') {
      refetch().then(() => {});
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <Card
      sx={{
        maxWidth: {
          xs: '100%',
          md: '300px',
        },
        py: 2,
        px: 3,
        border: purchasedCard ? '3px solid' : '0',
        borderImageSlice: purchasedCard ? 20 : 0,
        borderImageSource: purchasedCard
          ? `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
          : '',
        borderRadius: purchasedCard ? '20px' : '',
      }}
      data-aos="zoom-in"
      data-aos-duration="900"
    >
      <Stack spacing={5} direction="column" justifyContent="space-between">
        <Typography
          color={theme.palette.primary.main}
          textAlign="center"
          variant="subtitle1"
          sx={{
            fontSize: '12px',
            fontHeight: '12px',
            minHeight: '20px',
          }}
        >
          {bestSeller && 'Bestseller'}
          {priceSale && String(translate('provisionLanding.sale'))}
        </Typography>

        <Typography variant="subtitle1" color="text.primary">
          {String(translate(title))}
        </Typography>

        <PriceLabel
          price={handlePrice()}
          salePrice={price}
          isFromFinFinder={isFromFinFinder}
          promoLabel={isFromFinFinder || promo50Package}
          finfinderSale={finfinderSale}
        />

        <Stack
          direction="column"
          spacing={0.5}
          sx={{
            minHeight: '200px',
          }}
        >
          {translatedFeatures.map((feature, i) => (
            <Stack direction="row" alignItems="center" spacing={1} key={i}>
              <Iconify icon="ci:check" color="success.main" />
              <Typography variant="body2" color="text.secondary">
                {String(feature)}
              </Typography>
            </Stack>
          ))}
          {productId === 'family' && isAuthenticated && !isHomePage && (
            <>
              <MembersNumber
                membersNo={membersNo}
                handleMembersNo={handleMembersNo}
                disabled={disableMemberInput}
              />
              {(user?.subscription_package === 'family' ||
                user?.subscription_package === 'family_member') && (
                <BuyMore
                  handleBuyMore={handleStartSession}
                  membersNo={membersNo}
                  userPackage={user?.subscription_package}
                  isLoading={isLoading || gettingManagePortal}
                />
              )}
            </>
          )}
        </Stack>
        {showSubscribeButton && (
          <SubscribeButton
            productId={productId}
            onStripeSession={handleStripeSession}
            isLoading={isLoading || gettingManagePortal}
            disabled={disableLowFamilyPurchase}
          />
        )}
      </Stack>
    </Card>
  );
};

export default PricingCard;
